import "./Divider.css"

function Divider(){
    return(
        <div className="divider">

        </div>
    )
}

export default Divider